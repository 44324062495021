import schttp from 'public/src/services/schttp'
import '@shein-aidc/business-abt-devtools/mobile-shein.css'
import { setLocalStorage } from '@shein/common-function'

if (typeof window !== 'undefined') {
  const { PUBLIC_CDN } = gbCommonInfo
  __webpack_public_path__ = `${PUBLIC_CDN}/she_dist/assets/` // 用于设置 webpack 的 publicPath，以便正确加载资源
}

async function abtDebugInstance() {
  const isdebug = typeof gbCommonInfo !== 'undefined' && gbCommonInfo.isDebug
  if (!isdebug) return

  const { createAbtDevtoolsHook } = await import(/* webpackChunkName: "abt_debug_hooks" */ '@shein-aidc/business-abt-devtools/mobile')
  createAbtDevtoolsHook({ schttp })

  if (location.href.includes('abtDebug=0')) {
    setLocalStorage({ key: 'abtDebug', value: 0 })
    location.href = location.href.replace(/abtDebug=0/, '')
    return
  }

  if (location.href.includes('abtDebug=1' || `${localStorage.abtDebug}` === '1')
  ) {
    const { createAbtDevtoolsApp } = await import(/* webpackChunkName: "abt_debug_app" */ '@shein-aidc/business-abt-devtools/mobile')
    setLocalStorage({ key: 'abtDebug', value: 1 })
    createAbtDevtoolsApp({ terminal: 'web' })
  }
}

export { abtDebugInstance }
