export default {
  //试用中心-首页-首页模块
  '1-1-1': 'index.js',
  //试用中心-首页-公共模块
  '1-1-2': 'common.js',
  //试用中心-个人中心模块
  '1-2-1': 'index.js',
  //试用中心-报告页模块
  '1-3-1': 'index.js',
  // 列表页-首页模块
  '1-4-1': 'index.js',
  // 列表页-筛选
  '1-4-2': 'filter.js',
  // 列表页-shop-look列表
  '1-4-3': 'shop-look.js',
  // 一口价列表页
  '1-4-4': 'under-price.js',
  // 插坑
  '1-4-5': 'flow_slot.js',
  // 详情页-模块
  '1-5-1': 'index.js',
  // 相似商品结果页
  '1-6-1': 'index.js',
  // 购物车页面
  '1-7-1': 'index.js',
  // 购物车checkout模块
  '1-7-2': 'checkout.js',
  // 购物车列表模块
  '1-7-3': 'list.js',
  // 购物车凑单模块
  '1-7-4': 'add-item.js',
  // 购物车促销模块
  '1-7-5': 'promotion.js',
  // 购物车无货弹窗
  '1-7-6': 'sold-out-box.js',
  '1-7-7': 'cart.js',
  '1-7-8': 'cart-appendage.js',
  // mini 购物车 CartReference
  '1-7-9': 'reference.js',
  // 非标准购物车
  '1-7-10': 'business-cart.js',
  // 商品详情页 other options 模块
  '1-8-1': 'other-options.js',
  // 商品详情页 get the look 模块
  '1-8-2': 'get-the-look.js',
  // 商品详情页 style gallery 模块
  '1-8-3': 'style-gallery.js',
  // 商品详情页
  '1-8-4': 'index.js',
  // 商品详情页 customer reviews 模块
  '1-8-5': 'customer-reviews.js',
  '1-8-6': 'product-intro.js',
  '1-8-7': 'related-search.js',
  '1-8-9': 'gallery.js',
  '1-8-10': 'combo-buy.js',
  // 闪购页面
  '1-9-1': 'index.js',
  '1-9-2': 'specials-zone.js',
  // 收藏夹
  '1-10-1': 'index.js',
  '1-10-2': 'share.js',
  // 下单页
  '1-11-1': 'index.js',
  '1-11-2': 'shopping_bag.js',
  '1-11-3': 'together-buy.js',
  // 支付成功页
  '1-12-1': 'index.js',
  // 支付失败页
  '1-12-2': 'failure.js',
  // 礼品卡详情页
  '1-13-1': 'index.js',
  // contact us页
  '1-14-1': 'index.js',
  // Me 地址页
  '1-16-1': 'index.js',
  // 订单详情
  '1-17-1': 'index.js',
  // 订单列表
  '1-18-1': 'index.js',
  // 二次下单页
  '1-19-1': 'index.js',
  // 礼盒雨
  '1-21-1': 'gift-box.js',
  // 打地鼠
  '1-21-3': 'hit-the-gopher.js',
  // 扭蛋机
  '1-21-5': 'egg-machine.js',
  // 接水果
  '1-21-6': 'pick-fruit.js',
  // 扭蛋机
  '1-21-9': 'designer-detail.js',
  // 游戏公共
  '1-21-15': 'game-common.js',
  // 个人中心 优惠券页
  '1-22-1': 'coupon.js',
  // 个人中心
  '1-22-2': 'index.js',
  // 个人中心 积分页
  '1-22-3': 'points.js',
  // 个人中心 站内信
  '1-22-4': 'message.js',
  // 个人中心 钱包
  '1-22-5': 'wallet.js',
  // 个人中心 最近浏览
  '1-22-6': 'recently-view.js',
  // 个人中心 支付选项
  '1-22-7': 'payment-options.js',
  // 个人中心付费会员
  '1-22-8': 'prime.js',
  // 个人中心超省卡
  '1-22-9': 'saver.js',
  // 个人中心 试用会员页
  '1-22-10': 'prime-free-trial.js',
  // 个人中心 礼品卡
  '1-22-11': 'gift-card.js',
  // 个人中心 问卷列表
  '1-22-12': 'survey.js',
  // 新版列表页
  '1-23-1': 'feed-back.js',
  '1-24-1': 'index.js',
  // 礼品卡订单详情
  '1-25-1': 'index.js',
  // 礼品卡订单列表
  '1-26-1': 'index.js',
  // 退货页面
  '1-27-1': 'index.js',
  // 退货退款记录页面
  '1-27-2': 'refund-return-record.js',
  // 撤销权
  '1-27-3': 'order-revoke-power.js',
  // 待寄件退货单聚合页
  '1-27-4': 'order-return-package.js',
  // 订单评论页面
  '1-28-1': 'index.js',
  // 数据主体权利中心页面
  '1-30-1': 'index.js',
  // 卡支付页面
  '1-29-1': 'index.js',
  // 部分取消商品页
  '1-31-1': 'index.js',
  // 整单取消页
  '1-31-2': 'refund-order.js',
  // 部分取消商品第二部
  '1-32-1': 'index.js',
  // 机器人
  '1-33-1': 'index.js',
  // notification
  '1-34-1': 'index.js',
  // 合单承接页
  '1-35-1': 'index.js',
  // 海关拦截包裹清单页
  '1-36-1': 'index.js',
  // 巴西清关页
  '1-36-2': 'clearance.js',
  // 文章页
  '1-37-1': 'index.js',
  // paycod页面
  '1-38-1': 'index.js',
  // 发票中心列表
  '1-39-1': 'index.js',
  // 帐号删除页面
  '1-43-1': 'index.js',
  // 订单拒收
  '1-42-1': 'index.js',
  // romwe vip
  '1-44-1': 'index.js',
  // 评论中心
  '1-45-1': 'index.js',
  // sheinx设计师列表
  '1-46-1': 'index.js',
  // verification
  '1-47-1': 'index.js',
  // 分享券活动
  '1-48-1': 'index.js',
  // 店铺评论页
  '1-49-1': 'index.js',
  // 礼品卡下单页
  '1-50-1': 'index.js',
  // 账户安全页
  '1-51-1': 'security.js',
  // 邮箱解绑
  '1-52-1': 'index.js',
  // 手机号换绑
  '1-53-1': 'index.js',
  // 支付结果未知状态页
  '1-54-1': 'index.js',
  // 店铺页
  '1-55-1': 'index.js',
  // 店铺品类页
  '1-55-2': 'store-category.js',
  // 店铺推荐模块
  '1-55-3': 'store-recommend.js',
  // 回归用户券包弹窗
  '1-56-1': 'index.js',
  // 店铺收藏列表落地页
  '1-57-1': 'store-following.js',
  // 营销人群差异化承接页
  '1-58-1': 'index.js',
  // 新物流轨迹页
  '1-59-1': 'index.js',
  // 评论详情页
  '1-60-1': 'index.js',
  // 价保落地页
  '1-61-1': 'price-guarantee',
  // 自主换货
  '1-62-1': 'index.js',
  '1-62-2': 'index.js',
  // 不贵频道页
  '1-63-1': 'index.js',
  // 品牌频道页
  '1-64-1': 'index.js',
  '1-64-2': 'brand.js',
  '1-65-1': 'index.js',
  // news
  '1-66-1': 'index.js',
  // 趋势频道
  '1-67-1': 'index.js',
  // 趋势落地页
  '1-68-1': 'index.js',
  // 评论详情页
  '1-69-1': 'index.js',
  // 榜单落地页
  '1-70-1': 'index.js',
}
